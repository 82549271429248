/**=====================
     webinar Page Scss
==========================**/
.webinar-waiting {
  &-container {
    text-align: center;
    padding: 30px;
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    margin: 30px 0;
  }

  &-image { 
    max-width: 600px;
    margin: 0 auto 20px;
  }
}

.webinar-info-container {
  margin-bottom: 25px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.webinar-title {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #5e0937;
}

.webinar-meta {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;

  &-item {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #555;

    i {
      margin-right: 8px;
      color: #5e0937;
    }
  }
}

.webinar-status {
  &-title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #5e0937;
  }

  &-message {
    font-size: 18px;
    margin-bottom: 10px;
    color: #333;
  }

  &-subtitle {
    font-size: 14px;
    color: #666;
  }
}

.spinner-border {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 10px;
  vertical-align: middle;
}

.alert {
  &-success .webinar-status-title {
    color: #155724;
  }

  &-warning .webinar-status-title {
    color: #856404;
  }

  &-danger .webinar-status-title {
    color: #721c24;
  }
}